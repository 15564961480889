import { FC } from 'react';

const FalledIcon: FC = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_238)">
        <path
          d="M10 40C10 43.9397 10.776 47.8407 12.2836 51.4805C13.7913 55.1203 16.001 58.4274 18.7868 61.2132C21.5726 63.999 24.8797 66.2087 28.5195 67.7164C32.1593 69.224 36.0603 70 40 70C43.9397 70 47.8407 69.224 51.4805 67.7164C55.1203 66.2087 58.4274 63.999 61.2132 61.2132C63.999 58.4274 66.2087 55.1203 67.7164 51.4805C69.224 47.8407 70 43.9397 70 40C70 36.0603 69.224 32.1593 67.7164 28.5195C66.2087 24.8797 63.999 21.5726 61.2132 18.7868C58.4274 16.001 55.1203 13.7913 51.4805 12.2836C47.8407 10.776 43.9397 10 40 10C36.0603 10 32.1593 10.776 28.5195 12.2836C24.8797 13.7913 21.5726 16.001 18.7868 18.7868C16.001 21.5726 13.7913 24.8797 12.2836 28.5195C10.776 32.1593 10 36.0603 10 40Z"
          stroke="white"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40 30V43.3333"
          stroke="white"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40 53.3333V53.3667"
          stroke="white"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_238">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FalledIcon;
