import { FC } from 'react';
import css from './button.module.css';
import cn from 'classnames';
import CopyIcon from './icon-copy.component';

interface ButtonProps {
  readonly copy?: boolean;
  readonly value?: string;
  readonly color?: 'gray' | 'white' | 'black' | 'green';
  readonly onClick: () => void;
  readonly disabled?: boolean;
}

const Button: FC<ButtonProps> = ({ copy, value, color, disabled, onClick }) => {
  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        !disabled && onClick();
      }}
      className={cn(css.wrap, {
        [css.disabled]: disabled,
        [css.copy]: copy,
        [css.submit]: !copy,
        [css.gray]: color === 'gray',
        [css.green]: color === 'green',
        [css.black]: color === 'black',
        [css.white]: color === 'white',
      })}
    >
      <span>{value}</span>
      {copy && <CopyIcon />}
    </button>
  );
};
export default Button;
