import './App.css';

import MainPage from './components/pages/mainPage.component';

function App() {
  return (
    <div className="App">
      <MainPage />
    </div>
  );
}

export default App;
