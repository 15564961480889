import React, { createContext, useState, ReactNode } from 'react';

interface PaymentContextProps {
  wallet: string;
  setWallet: (wallet: string) => void;
  amount: string;
  setAmount: (amount: string) => void;
  paymentIdUrl: string;
  setPaymentIdUrl: (paymentIdUrl: string) => void;
}

interface PaymentProviderProps {
  children: ReactNode;
}

export const PaymentContext = createContext<PaymentContextProps>({
  wallet: '',
  setWallet: () => {},
  amount: '',
  setAmount: () => {},
  paymentIdUrl: '',
  setPaymentIdUrl: () => {},
});

export const PaymentProvider = ({ children }: PaymentProviderProps) => {
  const [wallet, setWallet] = useState('');
  const [amount, setAmount] = useState('');
  const [paymentIdUrl, setPaymentIdUrl] = useState('');

  return (
    <PaymentContext.Provider
      value={{
        wallet,
        setWallet,
        amount,
        setAmount,
        paymentIdUrl,
        setPaymentIdUrl,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
