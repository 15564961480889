import React, { useState, useEffect } from 'react';
import css from './countdownTimer.module.css';

interface CountdownTimerProps {
  initialTime: string;
  onTimeUp: () => void;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  initialTime,
  onTimeUp,
}) => {
  const [time, setTime] = useState<number>(parseTime(initialTime));

  useEffect(() => {
    if (time <= 0) {
      onTimeUp();
      return;
    }

    const timer = setInterval(() => {
      setTime((prevTime) => Math.max(prevTime - 1, 0));
    }, 1000);

    return () => clearInterval(timer);
  }, [time, onTimeUp]);

  function parseTime(timeString: string): number {
    const [minutes, seconds] = timeString.split(':').map(Number);
    return minutes * 60 + seconds;
  }

  function formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0');
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  }

  return <span className={css.time}>{formatTime(time)}</span>;
};

export default CountdownTimer;
