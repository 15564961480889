import { FC } from 'react';
import css from './overlay.module.css';
interface OverlayProps {
  isVisible: boolean;
}
const Overlay: FC<OverlayProps> = ({ isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return <div className={css.wrap}></div>;
};

export default Overlay;
