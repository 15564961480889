import React from 'react';
import css from './PopUp.module.css';

interface PopUpProps {
  onProceed: () => void;
}

const PopUp: React.FC<PopUpProps> = ({ onProceed }) => {
  return (
    <div className={css.overlay}>
      <div className={css.popup}>
        <h3 className={css.title}>Сервис крипто платажей</h3>
        <p className={css.description}>
          Перейдя к оплате необходимо будет в течении 20-ти минут перевести USDT в
          нужном количестве на кошелек в сети Tron.
        </p>
        <p className={css.description}>
          Сумма и адрес кошелька будут указаны на странице оплаты.
        </p>
        <button className={css.button} onClick={onProceed}>
          Перейти к оплате
        </button>
      </div>
    </div>
  );
};

export default PopUp;
